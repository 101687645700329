<template>
  <div class="sign-up" ref="signUp">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm
        :showDriverName="true"
        :chooseToday="true"
        @getFormVal="onSearch"
      >
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </analysisForm>
    </div>
    <el-table
      id="outputTable"
      :data="tableData"
      stripe
      :height="tableHeight"
      width="100%"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="150"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="licensePlateColor" label="车牌颜色">
        <template slot-scope="scope">{{
          colorObj[Number(scope.row.licensePlateColor)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.type)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="driverName"
        label="驾驶员姓名"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="credentialsNo"
        label="驾驶员卡号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="signTime"
        label="登签时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="gooffTime"
        label="退签时间"
        width="160"
      ></el-table-column>

      <el-table-column prop="signStatus" label="登退签状态" width="120">
        <template slot-scope="scope">{{
          signStatusObj[Number(scope.row.signStatus)]
        }}</template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisForm.vue";
import { queryDriverSignForm } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

export default {
  name: "signUp",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      typeObj: {},
      signStatusObj: {},
      colorObj: {},
      total: 0,
      exportForm: {},
      downLoadStatus: false,
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.signUp.clientHeight;
      let buttonsHeight = this.$refs.functionButtons.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10;
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId"),
        createTime: formatDate(new Date()),
        fileName: `登签退签统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/vehicleStatistics/driverSignExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击查询
    onSearch (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.driverName = value.driverName;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    //获取数据
    getListByField () {
      let data = { ...this.form };
      queryDriverSignForm(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1;
            this.getListByField();
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getListByField();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,D0011" }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY);
          this.colorObj = formatDict(res.data.CPYS);
          this.signStatusObj = formatDict(res.data.D0011);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.sign-up {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .sign-up {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    /deep/.analysisForm .el-form {
      /**浏览器宽度 */
      @media screen and (max-width: 1710px) {
        grid-template-columns: 33% 33% 33%;
      }

      /**浏览器宽度 */
      @media screen and (max-width: 1366px) {
        grid-template-columns: 33% 33% 33%;
      }
    }
  }
}
</style>
